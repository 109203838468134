<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1>Grant Knipp</h1>

          <p>
            A Louisville native, Grant grew up playing in the J-town Youth
            Baseball League. Grant attended Louisville Male High 2016-2020 and
            had a great high school career. Grant was a top prospect coming out
            of high school, 2020 he was the PBR #1 catcher in state and won the
            Johnny Bench Award (KY). Out of high school Grant signed to play at
            the University of Alabama, where he redshirted (2021). He
            transferred and is currently attending Campbell Universtiy in Buines Creek, NC.
          </p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" lg="4">
          <v-card max-width="300" max-height="300" hover>
            <v-img
              class="align-center"
              max-width="300"
              max-height="300"
              :src="KnippHitting"
            ></v-img>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" lg="8">
          <h2>Train with Grant</h2>

          <p>
            Grant focuses on the Big 3 of Catching:

            <br />
            - Receiving

            <br />
            - Blocking

            <br />
            - Throwing

            <br />
          </p>

          <p>As well as mobility, footwork and mental side of the catching.</p>

          <h3>Price</h3>
          <p>
            $40 - 30 minutes

            <br />
            $75 - 60 minutes
          </p>

          <v-btn href="tel:5025924655" color="primary">Call Now</v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <h2>Accolades</h2>
          <p>

            2019 (High School)
            <br />
            - All-District, All-Region, 1st Team All-State
            <br />

            <br />
            2020 (High School)
            <br />
            - Johnny Bench Award (KY)
            <br />
            - PBR #1 Catcher in Kentucky
            <br />
            - PBR #2 Overall Player in Kentucky
            <br />

            <br />
            2023 (College)
            <br />
            - Big South 1st Team All Conference
            <br />
            - Big South Tournament All-Tournament Team
            <br />
            - Big South Tournament MVP
            <br />
            - NCAA Columbia Regional All-Tournament Team
          </p>
        </v-col>

        <v-col cols="12" sm="6">
          <v-card
            max-width="300"
            max-height="300"
            hover
            target="_blank"
            href="https://linktr.ee/podunfinished"
          >
            <v-img
              class="align-center"
              max-width="300"
              max-height="300"
              :src="GrantJumping"
            ></v-img>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-img class="dark align-center mt-4" :src="PDTrain"></v-img>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import GrantJumping from '@/assets/images/GrantJumping.png'
import KnippHitting from '@/assets/images/KnippHitting.png'
import AndersonMillerPodcast from '@/assets/images/AndersonMillerPodcast.jpeg'
import PDTrain from '@/assets/images/PDTrain.svg'

export default {
  data() {
    return {
      GrantJumping: GrantJumping,
      AndersonMillerPodcast: AndersonMillerPodcast,
      PDTrain: PDTrain,
      KnippHitting: KnippHitting,
    }
  },
}
</script>

<style></style>
